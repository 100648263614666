<template>
    <div style="margin-top: 30px;">
        <el-form :model="form" label-width="120px" size="large">
        <el-form-item label="服务器地址">
                <el-col :span="16">
                    <el-input v-model="form.smtp"  placeholder="smtp.126.com"/>
                </el-col>
                </el-form-item>
                <el-form-item label="服务器端口">
                <el-col :span="16">
                    <el-input v-model="form.port" placeholder="465"/>
                </el-col>
                </el-form-item>
                <el-form-item label="发件人姓名">
                <el-col :span="16">
                    <el-input v-model="form.fromname" />
                </el-col>
                </el-form-item>
                <el-form-item label="smtp帐号">
                <el-col :span="16">
                    <el-input v-model="form.username" placeholder="邮箱帐号"/>
                </el-col>
                </el-form-item>
                <el-form-item label="smtp密码">
                <el-col :span="16">
                    <el-input v-model="form.password" placeholder="邮箱密码"  type="password" />
                </el-col>
                </el-form-item>
                <el-form-item label="发件人邮箱">
                <el-col :span="16">
                    <el-input v-model="form.fromm" placeholder="发件人邮箱地址" />
                </el-col>
                </el-form-item>

                <el-form-item>
                <el-button type="primary" @click="onSubmit">保存</el-button>
            </el-form-item>
        </el-form>
    </div>
    
        
</template>
<script>
import { reactive } from "vue";
import { Mailinfo, EditMinfo } from "@/network/mail";
import { useRouter } from "vue-router";
import { ElMessage } from "element-plus";

export default {
    setup() {
        const form = reactive({
            smtp: "",
            port: "",
            fromname: "",
            username: "",
            password: "",
            fromm: ""
        });
        Mailinfo().then((e)=>{
            form.smtp = e.smtp;
            form.port = e.port;
            form.fromname = e.fromname;
            form.username = e.username;
            form.password = e.password;
            form.fromm = e.fromm;
            
        });
        const router = useRouter();
        const onSubmit = () => {
            EditMinfo(form).then((res) => {
                if (res != 1) {
                    ElMessage({ message: "修改成功", type: "success" });
                }
            });
        };
        return {
            form,
            onSubmit,
        };
    }
};
</script>