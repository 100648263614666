<template>
    <Div>
        <!-- <el-button type="primary" size="large" icon="Plus" style="margin-bottom:20px" @click="showDialog(1)"> 添加</el-button> -->
        <el-button type="primary" size="large" icon="Message" @click="handleGetSelection">批量发邮件</el-button>
        <div>
            <el-table :data="tableData"  :key="index" style="width: 100%;margin-bottom: 20px;" row-key="id" :header-cell-style="{background:'#f9f9f9'}" stripe default-expand-all
            v-loading="loading" ref="multipleTable" @selection-change="handleSelectionChange">
                <el-table-column type="selection" width="55">
                </el-table-column>
                <el-table-column prop="id" label="ID" width="55"></el-table-column>
                <el-table-column prop="name" label="名称" width="120"></el-table-column>
                <el-table-column label="状态" width="120">
                    <template #default="scope">
                        <el-link type="danger" v-if="scope.row.zt == '0'">{{scope.row.zt_s}}</el-link>
                        <el-link type="success" v-if="scope.row.zt == 1">{{scope.row.zt_s}}</el-link>
                    </template>
                </el-table-column>
                <el-table-column prop="email" label="邮箱">
                    <template #default="scope">
                        <text style="margin-right: 10px;">{{scope.row.email}} </text>
                        <el-button type="primary" icon="Message" @click="sendemail(scope.row)"/>
                    </template>
                    
                </el-table-column>
                <el-table-column prop="phone" label="手机号" width="150"></el-table-column>
                <el-table-column prop="time" label="留言时间" width="150"></el-table-column>

                <el-table-column  label="操作">
                    <template #default="scope">
                        <el-button type="warning" size="mini" icon="View" @click="showDialog(2,scope.row)">查看</el-button>
                        <el-button type="danger" size="mini" icon="Delete" @click="delData(scope.row)">删除</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination layout="total,prev, pager, next, jumper,  size" background :total="total" :page-size="limit" @current-change="CurrentChange" />
            <!-- 添加修改 -->
            <el-dialog v-model="dialog" :title="title">
                <el-form :model="form" label-width="120px" size="large">
                    <el-form-item label="名称">
                        <el-input v-model="form.name" placeholder="" disabled/>
                    </el-form-item>
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" placeholder="" disabled/>
                    </el-form-item>
                    <el-form-item label="手机">
                        <el-input v-model="form.phone" placeholder="" disabled/>
                    </el-form-item>
                    <!-- <el-form-item label="公司名">
                        <el-input v-model="form.company" placeholder="" disabled/>
                    </el-form-item> -->
                    <el-form-item label="咨询产品">
                        <el-input v-model="form.product" placeholder="" disabled/>
                    </el-form-item>
                    <!-- <el-form-item label="数量">
                        <el-input v-model="form.quan" placeholder="" disabled/>
                    </el-form-item>
                    <el-form-item label="图片">
                        <template #default="scope">
                            <el-image v-if="form.img" :src="base + form.img" :preview-src-list="[base + form.img]" fit="fill"  style="margin-top: 5px;width: 60px;height: 60px;border: 1px solid black; "></el-image>
                            点击放大
                        </template> 
                    </el-form-item> -->
                    <el-form-item label="信息">
                        <el-input
                        v-model="form.mes"
                        :autosize="{ minRows: 4, maxRows: 8 }"
                        type="textarea"
                        placeholder=""
                        disabled
                    />
                    </el-form-item>


            
                    
                </el-form>
                <!-- <template #footer>
                    <span class="dialog-footer">
                        <el-button size="default" @click="dialog = false">取消</el-button>
                        <el-button size="default" type="primary" @click="submitForm()">确认</el-button>
                    </span>
                </template> -->
            </el-dialog>

             <!-- 发邮件 -->
             <el-dialog v-model="dialogem" :title="title">
                <el-form :model="form" label-width="120px" size="large">
                    <el-form-item label="邮箱">
                        <el-input v-model="form.email" placeholder="" disabled/>
                    </el-form-item>
                    <el-form-item label="标题">
                        <el-input v-model="form.title" placeholder="" />
                    </el-form-item>
                    <quill-editor 
                        class="ql-editor"
                        v-model:content="form.content" 
                        contentType="html"
                        ref="myQuillEditor"
                        :options="editorOption"
                        @blur="onEditorBlur($event)"
                        @focus="onEditorFocus($event)"
                        @change="onEditorChange($event)"
                    />
                            
                </el-form>
                <template #footer>
                    <span class="dialog-footer">
                        <el-button size="default" @click="dialogem = false">取消</el-button>
                        <el-button size="default" type="primary" @click="submitFormEm()">发送邮件</el-button>
                    </span>
                </template>
            </el-dialog>
        </div>
    </Div>
</template>
<script>
import { toRefs, reactive, ref } from "vue";
import { FeedList, FeedSave, FeedDel} from "@/network/feed";
import { UpPic} from "@/network/index";
import { Tomail} from "@/network/mail";
import { ElMessage, ElMessageBox } from "element-plus";

import { Quill, QuillEditor } from '@vueup/vue-quill';
import '@vueup/vue-quill/dist/vue-quill.snow.css';
import ImageUploader from "quill-image-uploader";
Quill.register("modules/imageUploader", ImageUploader);
import { ImageDrop } from 'quill-image-drop-module'  //图片拖拽 
Quill.register('modules/imageDrop', ImageDrop)
import BlotFormatter from 'quill-blot-formatter'  //图片缩放
Quill.register('modules/blotFormatter', BlotFormatter)
import htmlEditButton from "quill-html-edit-button"; //源代码编辑
Quill.register({"modules/htmlEditButton": htmlEditButton});

export default {
    components: {
        QuillEditor,Quill,ImageDrop
    },
    setup() {
        const base =(process.env.VUE_APP_PUBLIC_PATH); // 获取基本URL
        const state = reactive({
            tableData:[],
            dialog : false,
            dialogem : false,
            parent_show : true,
            src_show : true,
            title : "添加",
            total: 0,
            tpage: 1,
            limit: 10,  //设置分页数
            form: {
                id:0,
                zt:1,

            },
            upload_img: base + "/admin/Index/upload_img",
        });
        FeedList().then((e)=>{
            state.tableData = e.lists;
            state.total = e.total;
        } )
        const showDialog = (e,row)=>{
            state.dialog = true;
            if(e == 1){
                // state.form = {
                //     t_id:0,
                //     tname: "",
                //     v_id:"",
                // };
                // state.title = "添加";
            }else{
                state.title = "查看";
                state.form = {
                    id: row.id,
                    zt: 1,
                    name: row.name,
                    email: row.email,
                    phone:row.phone,
                    company:row.company,
                    product:row.product,
                    quan:row.quan,
                    img:row.img,
                    mes:row.mes,
                };
                submitForm();
               
            }
        }
        const sendemail = (row)=>{
            state.dialogem = true;
            
                state.title = "发送邮件";
                state.form = {
                    email: row.email,
                    title:row.title,
                    content:row.content,
                };
                //防止内容为空，编辑器返值错误
                if(!row.content){
                    state.form.content = '<p>'
                };
        }
        const submitFormEm = ()=>{
            Tomail(state.form).then( (e)=>{
                if (e.code != 1) {
                    ElMessage({ message:'成功发送', type: "success" });
                    state.dialogem = false;
                    
                }else{
                    ElMessage({ message:'发送失败', type: "error" });
                }
            } )
        }
        const submitForm = ()=>{
            FeedSave(state.form).then( (e)=>{
                if (e != 1) {
                    ElMessage({ message:'成功查看', type: "success" });
                    FeedList({p:state.tpage}).then( (e)=>{
                        state.tableData = e.lists;
                    } )
                    
                }
            } )
        }
        const delData = (row={})=>{
            ElMessageBox.confirm("删除后不能恢复，确定删除吗？", "删除："+row.name, {
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                type: "warning",
            }).then( ()=>{
                FeedDel({id:row.id}).then( (e)=>{
                    if (e != 1) {
                        ElMessage({ message:'成功', type: "success" });
                        FeedList({p:state.tpage}).then( (e)=>{
                          state.tableData = e.lists;
                        } )
                    }
                } )
            } ).catch(() => {
                ElMessage({
                    type: "info",
                    message: "取消删除"
                })
            })
        }
        // 翻页方法
        const CurrentChange = (e)=>{

            FeedList({p:e}).then((res) => {
                    state.tableData = res.lists;
                    state.tpage = e;
            });
            
        }
         // 管理员token，上传图片接口需要
         const token = window.localStorage.getItem("token");
        //编辑器
        const editorOption = {
            modules: {

                toolbar: [
                    ['bold', 'italic', 'underline', 'strike'], // 加粗 斜体 下划线 删除线
                    ['blockquote', 'code-block'], // 引用  代码块
                    [{ header: 1 }, { header: 2 }], // 1、2 级标题
                    [{ list: 'ordered' }, { list: 'bullet' }], // 有序、无序列表
                    [{ script: 'sub' }, { script: 'super' }], // 上标/下标
                    [{ indent: '-1' }, { indent: '+1' }], // 缩进
                    [{ direction: 'rtl' }], // 文本方向
                    // [{ size: ['12px', false, '16px', '18px', '20px', '30px'] }], // 字体大小
                    [{ header: [1, 2, 3, 4, 5, 6, false] }], // 标题
                    [{ color: [] }, { background: [] }], // 字体颜色、字体背景颜色
                    [{ font: [false, 'SimSun', 'SimHei', 'Microsoft-YaHei', 'KaiTi', 'FangSong', 'Arial'] }], // 字体种类
                    [{ align: [] }], // 对齐方式
                    ['clean'], // 清除文本格式
                    ['link', 'image', 'video'], // 链接、图片、视频
                ],
                 // 图片拖拽
                 imageDrop: true,
                 // 图片缩放
                 blotFormatter: {
                    // overlay: {
                    //    style: {
                    //        border: '2px solid red',
                    //    }
                    // },
                    toolbar: {
                    mainClassName: 'blot-formatter__toolbar'
                    }
                },
                 // html源代码编辑
                 htmlEditButton: {
                    debug: true, // logging, default:false
                    msg: "在这里修改HTML代码，点击完成将替换编辑器的内容", //Custom message to display in the editor, default: Edit HTML here, when you click "OK" the quill editor's contents will be replaced
                    okText: "完成", // Text to display in the OK button, default: Ok,
                    cancelText: "取消", // Text to display in the cancel button, default: Cancel
                    buttonHTML: "&lt;&gt;", // Text to display in the toolbar button, default: <>
                    buttonTitle: "Show HTML source", // Text to display as the tooltip for the toolbar button, default: Show HTML source
                    syntax: false, // Show the HTML with syntax highlighting. Requires highlightjs on window.hljs (similar to Quill itself), default: false
                    prependSelector: 'div#myelement', // a string used to select where you want to insert the overlayContainer, default: null (appends to body),
                    editorModules: {} // The default mod
                },
                
                 //上传图片存服务器
                imageUploader: {
                    upload: (file) => {
                        
                        return new Promise((resolve, reject) => {

                            var formData = new FormData();
                            formData.append('file', file);
                            formData.append('Authorization',token);
                            UpPic(formData).then((e)=>{
                            
                                // console.log(e,'@@')
                                if(e.code != 1 ){
                                    setTimeout(() => {
                                        resolve(base + e);
                                    }, 500);
                                }

                            });
                            // console.log(resolve,"555",file)
                            
                            
                        });
                    }
                },

            },
            
            placeholder: '请输入内容',
        };
        
        
        // 失去焦点事件
        const onEditorBlur = (quill) => {
            console.log('editor blur!', quill);
        };
        // 获得焦点事件
        const onEditorFocus = (quill) => {
            console.log('editor focus!', quill);
        };
        // 准备富文本编辑器
        const onEditorReady = (quill) => {
            console.log('editor ready!', quill);
        };
        // 内容改变事件
        const onEditorChange = ({ quill, html, text }) => {
            console.log('editor change!', quill, html, text);
            content.value = html;
        };

        const multipleTable = ref(null);
        const selectedRows = ref([]);
        //多选
        const handleSelectionChange = function (val) {
                selectedRows.value = val;
            
        };
        const handleGetSelection = () => {
           
            let amail = selectedRows.value.map(item => item.email);
            if (amail == 0) {
                ElMessage({ message: "请至少选择一条信息", type: "error" });
                return false;
            }
            // console.log(amail,'&&&&&&&');
            state.dialogem = true;
            state.title = "发送邮件";
            state.form = {
                email: amail,
                title:'',
                content:'',
            };
            //防止内容为空，编辑器返值错误
            if(!state.form.content){
                state.form.content = '<p>'
            };

        };
        return {
            ...toRefs(state),
            showDialog,
            submitForm,
            sendemail,
            submitFormEm,
            delData,
            base,
            CurrentChange,
            sendemail,

            editorOption,
            onEditorBlur,
            onEditorFocus,
            onEditorReady,
            onEditorChange,

            multipleTable,
            handleSelectionChange,
            handleGetSelection,
            selectedRows
        };
    }
};
</script>