<template>
    <el-button size="large" type="primary" icon="Service" @click="goToExternalSite">点击跳转到客服系统</el-button>
    <!-- <div style="height: 80.8vh;">
        <iframe
        :src="webinfo.kfdz"
        width="100%"
        height="100%"
        frameborder="0"
        allowfullscreen
        ></iframe>
    </div> -->
</template>
<script>
import { reactive, toRefs } from "vue";
import { Getconflists } from "@/network/login";
import { ElMessage } from "element-plus";
export default {
    setup() {
        const state = reactive({
            webinfo : [],
        });
        Getconflists().then( (e)=>{
            state.webinfo = e;
        });
        const goToExternalSite = () => {
            window.open(state.webinfo.kfdz, '_blank');
        };
        return {
            ...toRefs(state),
            goToExternalSite,
        };
    }
};
</script>